import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import axios from 'axios';
import GroupPlayerList from './GroupPlayerList';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`groups-tabpanel-${index}`}
            aria-labelledby={`group-tab-${index}`}
            style={{ height: '100%' }}
            {...other}
        >
            {value === index && (
                <Box sx={{ margin: 0, padding: 0, height: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function GroupUserManagement() {

    const [groups, setGroups] = useState([]);
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const response = await axios.get('/group')
            setGroups(response.data);
        } catch (error) {
            toast.error("Error loading groups");
            console.log(error);
        }
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}>
                <Tabs value={tab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                    {groups.map(g => (
                        <Tab key={g.id} label={g.name} />
                    ))}
                </Tabs>
            </Box>
            {groups.map((g, index) => (
                <CustomTabPanel key={g.id} value={tab} index={index}>
                    <GroupPlayerList group={g} />
                </CustomTabPanel>
            ))}
        </>
    );
}
