import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default function GroupStatusCombo({ name, value, onChange, variant = 'outlined', small = false, disabled = false }) {
    return (
        <FormControl>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
                labelId="status-select-label"
                name={name}
                value={value}
                label="Status"
                size={small ? 'small' : 'medium'}
                variant={variant}
                onChange={onChange}
                disabled={disabled}
            >
                <MenuItem value="ALLOW_LISTED_PLAYERS">Allow Listed Players</MenuItem>
                <MenuItem value="ALLOW_ALL_PLAYERS">Allow All Players</MenuItem>
                <MenuItem value="DENY_ALL_PLAYERS">Deny All Players</MenuItem>
            </Select>
        </FormControl>
    );
}
