import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, IconButton, Typography, Button } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingMask from '../components/LoadingMask';
import PlayerSelectDialog from '../player/PlayerSelectDialog';
import CreateButton from '../components/CreateButton';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAlert } from '../../alert/AlertContext';
import GroupStatusCombo from './GroupStatusCombo';

function useStatusDescription(status) {
    switch (status) {
        case "DENY_ALL_PLAYERS":
            return {
                description: "This group is denying all players, even if they are listed below",
                color: '#ff0000',
            };
        case "ALLOW_LISTED_PLAYERS":
            return {
                description: "This group is only allowing the players listed below",
                color: '#0000ff',

            };
        case "ALLOW_ALL_PLAYERS":
            return {
                description: "This group is allowing all players, even those not listed below",
                color: '#00ff00',
            };
        default:
            return {
                description: "Unknown status",
                color: '#ff0000',
            };
    }
}

export default function GroupPlayerList({ group }) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPlayerSelectDialog, setShowPlayerSelectDialog] = useState(false);
    const [editingStatus, setEditingStatus] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(group.status);
    const alert = useAlert();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const response = await axios.get(`/group/${group.id}/members`);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            alert("Error", "Error loading members");
        }
    };

    const addPlayer = async (player) => {
        try {
            setShowPlayerSelectDialog(false);
            const qs = require('qs');
            const response = await axios.post(`/entitlement/${group.id}`, qs.stringify({ 'playerId': player.id }));
            loadData();
            toast.success(response.data);
        } catch (error) {
            alert("Error", error.response.data);
        }
    };

    const onDeleteClick = (index) => {
        const items = [...data];
        const item = items[index];
        deletePlayer(item);
    };

    const deletePlayer = async (player) => {
        try {
            const response = await axios.delete(`/entitlement/${group.id}?playerId=${player.id}`);
            loadData();
            toast.success(response.data);
        } catch (error) {
            alert("Error", error.response.data);
        }
    };

    const saveStatus = async () => {
        try {
            await axios.post(`/group/${group.id}/status`, selectedStatus);
            toast.success("Status updated successfully");
            setEditingStatus(false);
        } catch (error) {
            alert("Error", error.response.data);
        }
    };

    const status = useStatusDescription(selectedStatus);

    return (
        <Box>
            {showPlayerSelectDialog && <PlayerSelectDialog onSelect={addPlayer} onClose={() => setShowPlayerSelectDialog(false)} />}
            <Typography variant='h5'>{group.name}</Typography>
            <Typography variant='body1'>{group.description}</Typography>

            {editingStatus ? (
                <Box display="flex" alignItems="center" gap={1}>
                    <GroupStatusCombo small value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)} />
                    <Button variant="contained" color="primary" onClick={saveStatus}>Save</Button>
                </Box>
            ) : (
                <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant='body1'>{status.description}</Typography>
                    <Button variant="text" color="primary" onClick={() => setEditingStatus(true)}>Change</Button>
                </Box>
            )}

            <div style={{ backgroundColor: status.color, height: '2px', width: '100%', marginTop: '8px' }}></div>
            <CreateButton label='Add Player' onClick={() => setShowPlayerSelectDialog(true)} />
            <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>IGN</TableCell>
                            <TableCell sx={{ width: '2%' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingMask visible={loading} fullScreen={false} />
                        {!loading && data.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.ign}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onDeleteClick(index)}>
                                        <RemoveIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
