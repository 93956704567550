import React, { useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import { Stack, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import LoadingMask from '../components/LoadingMask';
import SearchButton from '../components/SearchButton';
import AddIcon from '@mui/icons-material/Add';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#player-list-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function PlayerSelectDialog({ onSelect, onClose }) {

    const [players, setPlayers] = useState([]);
    const [search, setSearch] = useState('');
    const [textFieldDirty, setTextFieldDirty] = useState(true);
    const [loading, setLoading] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const [message, setMessage] = useState(null);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setTextFieldDirty(true);
        setAddMode(false);
        setMessage(null);
    };

    const handleKeyPress = (event) => {
        if (addMode) {
            addPlayer();
            return;
        }
        if (event.key === 'Enter') {
            if (!textFieldDirty && players.length === 1) {
                onSelect(players[0]);
            } else {
                searchPlayers();
                setTextFieldDirty(false);
            }
        }
    };

    const searchPlayers = async () => {
        setLoading(true);
        const response = await axios.get(`/player/search?searchTerms=${search}`);
        setPlayers(response.data);
        setLoading(false);
        if (response.data.length === 0) {
            setAddMode(true);
            setMessage("Player not registered, press add to attempt to register");
        }
    };

    const addPlayer = async () => {
        setLoading(true);
        const qs = require('qs');
        try {
            const response = await axios.post('/player/', qs.stringify({ 'playerIgn': search }));
            setLoading(false);
            onSelect(response.data);
        } catch (error) {
            setLoading(false);
            setMessage(error.response.data);
        }
    };

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="player-list-title"
            scroll={"paper"}
            open={true}
            fullWidth
            PaperComponent={PaperComponent}
        >
            <Toolbar
                id="player-list-dialog-toolbar"
                sx={{ bgcolor: 'primary.main', color: 'white' }}
            >
                <DialogTitle id="player-list-dialog-title" sx={{ cursor: 'move' }}>Player Search</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <DialogContent dividers={true}>
                <Stack spacing={2} direction="row" display="flex">
                    <TextField
                        autoFocus
                        id="search"
                        label="Search"
                        variant="standard"
                        value={search}
                        fullWidth
                        size='small'
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyPress}
                    />
                    {addMode ?
                        <IconButton color="inherit" onClick={addPlayer}>
                            <AddIcon />
                        </IconButton>
                        :
                        <SearchButton onClick={searchPlayers} />
                    }

                </Stack>
                {!addMode ?
                    <TableContainer component={Paper} sx={{
                        minHeight: '400px',
                        maxHeight: '400px',
                        overflowY: 'auto'
                    }} >
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ wisdth: '2%' }}>IGN</TableCell>
                                    <TableCell>Account UUID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <LoadingMask visible={loading} fullScreen={false} />
                                {!loading &&
                                    <>
                                        {players.map((player) => (
                                            <TableRow
                                                hover
                                                key={player.id}
                                                onClick={(event) => onSelect(player)}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <TableCell>{player.ign}</TableCell>
                                                <TableCell>{player.uuid}</TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <>
                        <LoadingMask visible={loading} fullScreen={false} />
                        {message && <Typography variant='body1'>{message}</Typography>}
                        <Button onClick={() => setAddMode(false)}>Back To Search</Button>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
